import React, { useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

const KeramikimplantatePage = ({ data }) => {
  const { html } = data.markdownRemark;
  const pageFrontmatter = data.markdownRemark.frontmatter;

  const [disclosureOpenState, setDisclosureOpenState] = useState(false);

  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>Keramikimplantate - Zahnarztpraxis Dr. Sandra Faltermeier</title>
        <meta
          name="description"
          content="Mundgesundheit beginnt mit der Verwendung geeigneter Materialien. Wir verwenden in unserer Praxis Keramikimplantate der schweizer Firma SDS. Keramikimplantate aus Zirkonoxid sind im Vergleich zu Titan äußerst biokompatibel und es gibt keine bekannten Unverträglichkeiten oder Allergien."
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Keramikimplantate - Zahnarztpraxis Dr. Sandra Faltermeier"
        />
        <meta
          property="og:description"
          content="Mundgesundheit beginnt mit der Verwendung geeigneter Materialien. Wir verwenden in unserer Praxis Keramikimplantate der schweizer Firma SDS. Keramikimplantate aus Zirkonoxid sind im Vergleich zu Titan äußerst biokompatibel und es gibt keine bekannten Unverträglichkeiten oder Allergien."
        />
        <meta property="og:url" content="/leistungen/keramikimplantate" />
      </Helmet>
      <Layout>
        <div class="sm:mb-80p sm:px-25p">
          {pageFrontmatter.imageObject && (
            <GatsbyImage
              class="mb-40p"
              loading="eager"
              image={
                pageFrontmatter.imageObject.image.childImageSharp
                  .gatsbyImageData
              }
              alt={pageFrontmatter.imageObject.alt}
              title={pageFrontmatter.imageObject.title}
            />
          )}
        </div>
        <div class="max-w-1000p mx-auto px-5 mini:px-25p">
          <div
            data-sal={`${!pageFrontmatter.imageObject ? "slide-up" : null}`}
            data-sal-duration={`${!pageFrontmatter.imageObject ? "700" : null}`}
            data-sal-delay={`${!pageFrontmatter.imageObject ? "500" : null}`}
            data-sal-easing={`${!pageFrontmatter.imageObject ? "ease" : null}`}
          >
            <div
              class="markdownLeistungen mb-40p sm:mb-80p"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div class="space-y-40p sm:space-y-80p">
              <h3 class="text-secondary mb-15p sm:mb-30p mt-40p sm:mt-80p font-medium text-20p sm:text-25p">
                Der Prozess der Implantation:
              </h3>
              <div class="aspect-w-16 aspect-h-9 mb-40p sm:mb-80p">
                <iframe
                  src="https://www.youtube.com/embed/DXfwxGhB8Cs"
                  title="sds implatation video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="flex">
                <StaticImage src="../../img/Implantation-1.jpg" />
                <StaticImage src="../../img/Implantation-2.jpg" />
                <StaticImage src="../../img/Implantation-3.jpg" />
              </div>
            </div>
            <div>
              <h3 class="text-secondary mb-15p sm:mb-30p mt-40p sm:mt-80p font-medium text-20p sm:text-25p">
                Wieso farblose Implatate wichtig sind:
              </h3>
              <div class="flex">
                <StaticImage src="../../img/Titan_VS_Zirkon.jpg" />
                <StaticImage src="../../img/sds-mit-krone.jpg" />
              </div>
            </div>
            <div>
              {!disclosureOpenState ? (
                <div
                  role="button"
                  tabIndex={0}
                  onKeydown={() => setDisclosureOpenState(true)}
                  onClick={() => setDisclosureOpenState(true)}
                >
                  <h3
                    class="text-secondary mb-15p sm:mb-30p mt-40p sm:mt-80p font-medium text-20p sm:text-25p
                    cursor-pointer hover:underline"
                  >
                    Mehr zu SDS erfahren
                  </h3>
                </div>
              ) : (
                <h3 class="text-secondary mb-15p sm:mb-30p mt-40p sm:mt-80p font-medium text-20p sm:text-25p">
                  Mehr zu SDS erfahren
                </h3>
              )}

              {disclosureOpenState && (
                <div class="markdownLeistungen mb-40p sm:mb-80p">
                  <p>
                    Der Schweizer Keramik-Implantat Hersteller SDS SWISS DENTAL
                    SOLUTIONS ist Weltmarktführer für Keramik-Implantate und
                    kann bei der Entwicklung und Produktion seiner
                    Keramik-Implantate auf über 25 Jahre Erfahrung
                    zurückgreifen. Erfinder, Gründer und Inhaber des
                    Unternehmens ist Dr. Karl Ulrich Volz, der bereits um die
                    Jahrtausendwende Pionierarbeit im Bereich der metallfreien
                    Implantologie leistete und massgeblich zur Weiterentwicklung
                    dieser Branche beitrug.
                  </p>
                  <p>Überzeugende Vorteile von SDS Keramik-Implantaten:</p>
                  <ul>
                    <li>
                      SDS Keramik-Implantate sind absolut biokompatibel, damit
                      sehr gut verträglich und heilen fest in den Knochen ein
                      (Osseointegration)
                    </li>
                    <li>
                      Die langfristige Haltbarkeit ist unbegrenzt und liegt nach
                      aktuellem Wissen höher als die von natürlichen Zähnen
                    </li>
                    <li>
                      Die helle Farbe im Vergleich zu den schwarzgrauen
                      Titanimplantaten garantiert ein natürliches Lächeln und
                      eine herausragende Ästhetik
                    </li>
                    <li>
                      Auch in Sachen Stabilität sind Keramik-Implantate aus der
                      Hochleistungskeramik Zirkoniumoxid in der Zwischenzeit den
                      Titanimplantaten überlegen: bei gleichem Durchmesser
                      weisen sie eine höhere Bruchsicherheit auf
                    </li>
                    <li>
                      Dank des einzigartigen Implantat-Designs des Marktführers
                      SDS SWISS DENTAL SOLUTIONS ist in den meisten Fällen das
                      Setzen der Implantate direkt im Anschluss an die
                      Extraktion und somit in nur einem Eingriff möglich. In
                      diesen Fällen kann meist auch gleich ein neuer
                      provisorischer Zahn aufgesetzt werden
                    </li>
                    <li>
                      Das Risiko einer Periimplantitis (chronische, schmerzhafte
                      Entzündung des Zahnfleisches um ein Implantat herum), ist
                      bei Implantaten aus Keramik praktisch nicht gegeben{" "}
                    </li>
                  </ul>
                  <p>
                    SDS Keramik-Implantate sind zudem als einziges System
                    weltweit gleich zweifach mit dem CleanImplant Siegel
                    ausgezeichnet, das höchste Standards in Sachen Reinheit im
                    gesamten Herstellungsprozess und auf dem finalen Endprodukt
                    voraussetzt.
                  </p>
                  <p>
                    Als Patient treffen Sie mit Keramik-Implantaten von SDS
                    SWISS DENTAL SOLUTIONS in jedem Fall die beste Wahl.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div class="mt-40p sm:mt-80p flex flex-col space-y-5 sm:space-y-0 sm:flex-row justify-evenly items-center max-w-5xl mx-auto sm:space-x-5">
            <Link
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-secondary font-medium text-20p sm:text-25p text-center"
              to="/leistungen"
            >
              Zurück zur Leistungsübersicht
            </Link>
            <Link
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-20p sm:text-25p text-white bg-cta hover:bg-cta2 p-20p sm:px-25p sm:py-3 text-center"
              to="https://www.doctolib.de/einzelpraxis/regensburg/zahnarztpraxis-am-candis-fr-dr-sandra-faltermeier"
            >
              Jetzt Termin vereinbaren
            </Link>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default KeramikimplantatePage;

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { templateKey: { eq: "keramikimplantate-page" } }
    ) {
      frontmatter {
        title
        subtitle
        imageObject {
          title
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                formats: [WEBP, AUTO]
              )
            }
          }
        }
      }
      html
    }
  }
`;
